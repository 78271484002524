module.exports={
  "name": "webvr-polyfill",
  "version": "0.9.41",
  "homepage": "https://github.com/googlevr/webvr-polyfill",
  "authors": [
    "Boris Smus <boris@smus.com>",
    "Brandon Jones <tojiro@gmail.com>",
    "Jordan Santell <jordan@jsantell.com>"
  ],
  "description": "Use WebVR today, on mobile or desktop, without requiring a special browser build.",
  "devDependencies": {
    "chai": "^3.5.0",
    "jsdom": "^9.12.0",
    "mocha": "^3.2.0",
    "semver": "^5.3.0",
    "webpack": "^2.6.1",
    "webpack-dev-server": "2.7.1"
  },
  "main": "src/node-entry",
  "keywords": [
    "vr",
    "webvr"
  ],
  "license": "Apache-2.0",
  "scripts": {
    "start": "npm run watch",
    "watch": "webpack-dev-server",
    "build": "webpack",
    "test": "mocha"
  },
  "repository": "googlevr/webvr-polyfill",
  "bugs": {
    "url": "https://github.com/googlevr/webvr-polyfill/issues"
  }
}